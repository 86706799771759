import Member1 from "../../../assets/ryan.png";
import Member2 from "../../../assets/zoro.png";
import Member3 from "../../../assets/aliens.png";

function Team() {
  return (
    <>
      <section id="team" className="mentol--section section--spacing">
        <div className="container">
          <div className="row">
            <h2 className="h1 text-uppercase text-center col-12 text-dark-blue fw-900">
              meet the (human) team{" "}
            </h2>
            <div className="team-member-item col-12">
              <div className="team--member-avatar">
                <img src={Member1} alt="Ryan" />
              </div>
              <div className="team-member-bio">
                <h3 className="h1 team-member-name text-dark-blue fw-900">
                  ryan
                </h3>
                <span className="team-member-position fw-900">the brains</span>
                <p className="team-member-about">
                  Ryan has written code for over 25 years, working in both small
                  and large software companies. He has formed multiple startups
                  and has had catchy titles like CEO and CTO next to his name.
                  Ryan hated writing this. You can find him on{" "}
                  <a href="https://www.linkedin.com/in/ryanfriedrich/" target="_blank" rel="noreferrer">Linked In</a>
                </p>
              </div>
            </div>
            <div className="team-member-item col-12">
              <div className="team--member-avatar">
                <img src={Member2} alt="Zoroe" />
              </div>
              <div className="team-member-bio">
                <h3 className="h1 team-member-name text-dark-blue fw-900">
                  Zoroe
                </h3>
                <span className="team-member-position fw-900">the artist</span>
                <p className="team-member-about">
                  Zoroe started writing graffiti in the early 90’s. He’s spent
                  the past 20 years designing experiences for Amazon, Facebook,
                  SONY and many more. You can find him on <a href="https://www.youtube.com/c/zoroe" target="_blank" rel="noreferrer">YouTube</a>, <a href="https://www.linkedin.com/in/panosv/" target="_blank" rel="noreferrer">LinkedIn</a>, or
                  <a href="https://www.zoroeart.com/" target="_blank" rel="noreferrer">Zoroe.io</a>
                </p>
              </div>
            </div>
            <div className="team-member-item col-12">
              <div className="team--member-avatar">
                <img src={Member3} alt="advisor" />
              </div>
              <div className="team-member-bio">
                <h3 className="h1 team-member-name text-dark-blue fw-900">
                  art advisors
                </h3>
                <span className="team-member-position fw-900">the council</span>
                <p className="team-member-about">
                The council serves as our guiding light to ensure that we help nonprofits in need of our help. With the council’s help we ensure money goes to the kids in most need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
