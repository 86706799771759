import FooterLogo from '../assets/short_logo.png';

function Footer() {
    return(
        <footer>
            <div className="footer-content">
                <a href="/" className='navbar-brand'>
                    <img src={FooterLogo} alt="Short Logotype"/>
                </a>
                <span>© 2021 NON-HUMAN BEINGS NFT</span>
            </div>
        </footer>
    )
}

export default Footer;