import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";

import Home from "./pages/Home/Home";
import Mint from "./pages/Mint/Mint";
import NotFound from "./pages/NotFound/NotFound";

import "@fortawesome/fontawesome-free/js/all";

function App() {
  return (
    <Router>
      <Route
        render={({ location }) => {
          return (
            <PageTransition
              preset="cubeToLeft"
              transitionKey={location.pathname}
            >
              <div className="content">
                <Switch location={location}>
                  <Route exact path="/mint">
                    <Mint />
                  </Route>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </div>
            </PageTransition>
          );
        }}
      />
    </Router>
  );
}

export default App;
