import { useState } from "react";
import { useHistory } from "react-router-dom";
import PresaleClose from "./types/PresaleClose";
import Minting from "./types/Minting";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function Mint() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Header />
      <section className="minting--section position-relative">
        <button className="btn btn-back" onClick={handleGoBack}>
          <i className="fas fa-long-arrow-alt-left"></i>
        </button>
        {isOpen ? <Minting /> : <PresaleClose />}
      </section>
      <Footer />
    </>
  );
}

export default Mint;
