function Specs() {
  return (
    <>
      <section id="specs" className="pink--section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 spec--item text-center text-white">
              <h3 className="h1 mb-2 fw-bold">10,000</h3>
              <h4>10,000 random Non-Human Beings NFTs</h4>
            </div>
            <div className="col-lg-3 col-md-6 col-12 spec--item text-center text-white">
              <h3 className="h1 mb-2 fw-bold">0.05ETH</h3>
              <h4>Fair Launch and distribution - all Non-Human Beings have the same cost of 0.05 ETH</h4>
            </div>
            <div className="col-lg-3 col-md-6 col-12 spec--item text-center text-white">
              <h3 className="h1 mb-2 fw-bold">©</h3>
              <h4>Includes creative and commercial rights usage of your Non-Human for as long as you keep the NFT</h4>
            </div>
            <div className="col-lg-3 col-md-6 col-12 spec--item text-center text-white">
              <h3 className="h1 mb-2 fw-bold">100%</h3>
              <h4>Unlock additional benefits through roadmap activations and future phases</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Specs;
