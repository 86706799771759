import React from "react";
import { Link } from "react-router-dom";

import HomeImage from "../../../assets/home.png";

function Home() {
  return (
    <section id="home" className="position-relative content-inner d-flex align-items-center">
      <div className="container h-100">
        <div className="row h-100 home--content align-items-center position-relative">
          <div className="col-lg-6">
            <img src={HomeImage} alt="" className="section--image" />
          </div>
          <div className="home--title-wrapper col-lg-6">
            <h1 className="text-uppercase fw-bold">more than just another nft</h1>
            <p>
              Sure, we could have built a casino in the Metaverse. <br /> We
              could have even built a Metaverse bar.
            </p>
            <p>
              Instead, we decided to expose inner city youth to the power of art
              through NFTs and perhaps birth the next great NFT artists.
            </p>
            <div className="d-flex home--btns-container mt-5">
              <Link to="/mint" className="btn btn-primary fw-bold">
                Mint our NFT
              </Link>
              <a
                href="https://discord.gg/SkjuQGpF4r"
                className="btn btn-secondary fw-bold"
                target="_blank"
                rel="noreferrer"
              >
                Join our discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
