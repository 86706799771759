import CommunityImage from "../../../assets/community.png";

function Community() {
  return (
    <section id="community" className="bg-purple section--spacing">
        <div className="container h-100">
          <div className="row h-100 spec--content position-relative">
          <div className="col-lg-6">
              <img
                src={CommunityImage}
                alt=""
                className="section--image community--image"
              />
            </div>
            <div className="community-wrapper col-lg-6 text-white">
              <h2 className="text-uppercase fw-bold h1">
                community first
              </h2>
              <p>
              Too many NFTs hold no value over time. With NHBs not only do you acquire some cool art, but we keep the floors swept, and contribute a ton of money to children’s art charity. By joining our discord you can participate in deciding the charities as well as other decisions we make. 
              </p>
              <p>
              Our early adopters also get the ability to buy early, with no gas wars, in exchange for simply participating in the process.
              </p>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Community;
