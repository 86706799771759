import AboutImage from "../../../assets/spec.gif";

function About() {
  return (
    <section id="about" className="position-relative bg-mentol section--spacing">
        <div className="container h-100">
          <div className="row h-100 spec--content position-relative">
            <div className="specs-wrapper col-lg-6">
              <h2 className="text-uppercase fw-bold h1">
                What’s non-human beings?
              </h2>
              <p>
                Non Human Beings (NHBs) are just that – not human beings and the
                brainchild of two intergalactic psychonauts. One, a corporate
                designer by day and devious graffiti artist by night. The other,
                a washed up insomniac software developer.
              </p>
              <p>
                Both fell from tech corpo space to create an NFT collection
                constructed from over 500 assets to create a truly unique NFT
                project.
              </p>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <img
                src={AboutImage}
                alt=""
                className="section--image specs--image"
              />
            </div>
          </div>
        </div>
      </section>
  );
}

export default About;
