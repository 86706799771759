import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as Brand } from "../assets/brand.svg";

function Header() {
  const activeLinkRef = useRef(null);
  const headerRef = useRef(null);
  const burgerRef = useRef(null);
  const location = useLocation();
  const modalContentRef = useRef(null);

  const handleBurgerClick = (e) => {
    const target = e.currentTarget;
    if (target.classList.contains("active")) {
      target.classList.remove("active");
      return;
    }
    target.classList.add("active");
  };

  const handleLinkClick = (e) => {
    if (activeLinkRef.current?.classList.contains("active")) {
      activeLinkRef.current.classList.remove("active");
    }
    burgerRef.current?.classList.remove("active");

    e.currentTarget.classList.add("active");
    activeLinkRef.current = e.currentTarget;
  };

  const handleOutsideClick = (e) => {
    if (modalContentRef.current && modalContentRef.current?.contains(e.target))
      return;

    handleBurgerClick({ currentTarget: burgerRef.current });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <header ref={headerRef}>
      <div className="container-fluid px-5">
        <nav
          ref={modalContentRef}
          className={`navbar navbar-expand-lg ${
            location.pathname.length <= 1 ? "justify-content-end" : ""
          }`}
        >
          <a href="/" className="navbar-brand">
            <Brand />
          </a>
          {location.pathname.length <= 1 && (
            <>
              <div
                ref={burgerRef}
                className="burger_bar_container"
                onClick={handleBurgerClick}
              >
                <div className="bar1 bars"></div>
                <div className="bar2 bars"></div>
                <div className="bar3 bars"></div>
              </div>
              <div
                className="navbar-collapse header--content"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                  <li className="nav-item">
                    <a
                      onClick={handleLinkClick}
                      ref={activeLinkRef}
                      className="nav-link active"
                      href="#about"
                    >
                      <span>About</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={handleLinkClick}
                      className="nav-link"
                      href="#specs"
                    >
                      <span>Specs</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={handleLinkClick}
                      className="nav-link"
                      href="#community"
                    >
                      <span>Community</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={handleLinkClick}
                      className="nav-link"
                      href="#roadmap"
                    >
                      <span>Roadmap</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={handleLinkClick}
                      className="nav-link"
                      href="#team"
                    >
                      <span>The Team</span>
                    </a>
                  </li>
                  <li className="nav-item d-flex align-items-center">
                    <a
                      className="nav-link nav-link_icon"
                      href="https://discord.gg/SkjuQGpF4r"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-discord"></i>
                    </a>
                    <a
                      className="nav-link nav-link_icon"
                      href="https://twitter.com/NonHumanNFT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-flex flex-column home--btns-container mt-3 d-lg-none">
                    <Link to="/mint" className="btn btn-primary fw-bold mb-3 mx-0">
                      Mint our NFT
                    </Link>
                    <a
                      href="https://discord.gg/SkjuQGpF4r"
                      className="btn btn-secondary fw-bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Join our discord
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
