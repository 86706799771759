import MintImage from "../../../assets/spec.gif";

function PresaleClose() {
  return (
    <div className="container content-inner">
      <div className="row h-100 spec--content position-relative">
        <div className="specs-wrapper col-lg-6">
          <h2 className="text-uppercase fw-bold h1">Get in early</h2>
          <p>
            We’re not minting yet, but our early adopters get the ability to buy
            early, with no gas wars, in exchange for simply participating in the
            process.
          </p>
          <a
            href="https://discord.gg/SkjuQGpF4r"
            className="btn btn-secondary fw-bold mt-5"
            target="_blank"
            rel="noreferrer"
          >
            Join our discord
          </a>
        </div>
        <div className="col-lg-6 d-flex justify-content-end">
          <img src={MintImage} alt="" className="section--image specs--image" />
        </div>
      </div>
    </div>
  );
}

export default PresaleClose;
