function RoadMap() {
  return (
    <section id="roadmap" className="bg-mentol-light section--spacing">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center h1 text-uppercase">Roadmap</h2>
            <div className="roadmap--details-container position-relative">
              <div className="roadmap--container">
                <div className="roadmap--label">10% sold</div>
                <ul>
                  <li>
                    <span className="roadmap--text">
                      Pay our wives back and avoid divorce
                    </span>
                  </li>
                </ul>
              </div>
              <div className="roadmap--container">
                <div className="roadmap--label">23% sold</div>
                <ul>
                  <li>
                    <span className="roadmap--text">
                      Air Drop 20 NFTs to holders
                    </span>
                  </li>
                  <li>
                    <span className="roadmap--text">Community fund 5 ETH</span>
                  </li>
                </ul>
              </div>
              <div className="roadmap--container">
                <div className="roadmap--label">42% sold</div>
                <ul>
                  <li>
                    <span className="roadmap--text">
                      Charity to be selected by community ($40,000!)
                    </span>
                  </li>
                  <li>
                    <span className="roadmap--text">Community fund 5 ETH</span>
                  </li>
                  <li>
                    <span className="roadmap--text">
                      Air Drop 20 NFTs to holders
                    </span>
                  </li>
                </ul>
              </div>
              <div className="roadmap--container">
                <div className="roadmap--label">61% sold</div>
                <ul>
                  <li>
                    <span className="roadmap--text">Community fund 5 ETH</span>
                  </li>
                  <li>
                    <span className="roadmap--text">
                      Air Drop 20 NFTs to holders
                    </span>
                  </li>
                  <li>
                    <span className="roadmap--text">
                      Physical artwork giveaway
                    </span>
                  </li>
                </ul>
              </div>
              <div className="roadmap--container">
                <div className="roadmap--label">84% sold</div>
                <ul>
                  <li>
                    <span className="roadmap--text">
                      Another charity to be selected by community ($40,000!)
                    </span>
                  </li>
                  <li>
                    <span className="roadmap--text">Community fund 5 ETH</span>
                  </li>
                  <li>
                    <span className="roadmap--text">
                      Air Drop 20 NFTs to holders
                    </span>
                  </li>
                </ul>
              </div>
              <div className="roadmap--container">
                <div className="roadmap--label">100% sold</div>
                <ul>
                  <li>
                    <span className="roadmap--text">
                      Sweeping the floor begins
                    </span>
                  </li>
                  <li>
                    <span className="roadmap--text">
                      Air drop 20 NFTs to holders{" "}
                    </span>
                  </li>
                  <li>
                    <span className="roadmap--text">Community fund 5 ETH</span>
                  </li>
                  <li>
                    <span className="roadmap--text">
                      5 Unique “1 of 1” NHBs Air Dropped to holders
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadMap;
