import { default as HomeSection } from "./sections/Home";
import Specs from "./sections/Specs";
import About from "./sections/About";
import Community from "./sections/Community";
import RoadMap from "./sections/RoadMap";
import Team from "./sections/Team";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

function Home() {
  return (
    <>
      <Header/>
      <HomeSection />
      <About/>
      <Specs />
      <Community/>
      <RoadMap/>
      <Team/>
      <Footer/>
    </>
  );
}

export default Home;
