import { useState } from 'react';

const {REACT_APP_MINT_PRICE: mintPrice} = process.env;

function Minting () {
    const [value, setValue] = useState(1);
    const [isConnected, setIsConnected] = useState(false);
    const [calculatedValue, setCalculatedValue] = useState(Number(mintPrice));
    const  handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('aardion')
    }

    const  handleInputChange = ({target}) => {
        const price = mintPrice * target.value;
        setValue(target.value);
        setCalculatedValue(price.toFixed(2));
    }

    return (
        <div className="container content-inner">
          <div className="row">
            <div className="row h-100 spec--content position-relative">
              <div className="specs-wrapper col-lg-6">
                <h2 className="text-uppercase fw-bold h1">
                  <span className="color--pink">now available</span> <br />
                  for public <br />
                  minting
                </h2>
                <p>
                    We’re not minting yet, but our early adopters get the ability to buy early, with no gas wars, in exchange for simply participating in the process.
                </p>
                <a
                    href="https://discord.gg/SkjuQGpF4r"
                    className="btn btn-secondary fw-bold mt-5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join our discord
                  </a>
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
                    <div className="mint--form-container">
                        <div className="mint--details">
                            <h5 className="text-uppercase fw-bold mb-0">Mint non-human beings</h5>
                            <p>
                            Enter the amount of Hon-Human Beings you would like to purchase
                            </p>
                        </div>
                        <form onSubmit={handleFormSubmit} className="mint--form">
                            <div className="mint--input-container">
                                <label htmlFor="mintInput">Price per Non-Human Being <b>0.05 ETH</b> each</label>
                                <div className='mint--input-wrapper'>
                                    <input id="mintInput" type='number' min='1' max='10' value={value} onChange={handleInputChange} disabled={!isConnected} />
                                    <span className="mint--count">10 max</span>
                                </div>
                                <div className='mint--total d-flex align-items-center justify-content-between'>
                                    <span className='mint--total-title'>Total</span>
                                    <span className='mint--total-number'>{calculatedValue} ETH</span>
                                </div>
                                <button type="submit" className="mint--btn btn btn-primary fw-bold">
                                    {!isConnected ? "Connect Wallet" : "Mint Now!"}
                                </button>
                            </div>
                        </form>
                    </div>
                   
              </div>
            </div>
          </div>
        </div>
      );
}


export default Minting;